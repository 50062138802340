import {Component} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {Router} from '@angular/router';
import {PopoverController} from '@ionic/angular';
import {HomePage} from '../../pages/home/home.page';
import {environment} from '../../../environments/environment';

@Component({
	selector: 'app-home-popover',
	templateUrl: './home-popover.component.html',
	styleUrls: ['home-popover.component.scss']
})
export class HomePopoverComponent {
	environment = environment;

	constructor(public repository: RepositoryService, private router: Router, private popoverCtrl: PopoverController) {
	}

	async change() {
		await this.popoverCtrl.dismiss();
		await HomePage.navigate(this.router, this.repository);
	}

	async dismiss() {
		await this.popoverCtrl.dismiss();
	}
}
