import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import RepositoryDirective from 'src/smoothr-web-app-core/directives/repository-directive';
import ArticleGroup from 'src/smoothr-web-app-core/models/ArticleGroup';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';

@Component({
	selector: 'app-note-article-modal',
	templateUrl: './note-article-modal.component.html',
	styleUrls: ['note-article-modal.component.scss']
})
export class NoteArticleModalComponent extends RepositoryDirective implements OnInit {
	articleGroup: ArticleGroup;
	inputNote: string;

	constructor(public repository: RepositoryService, private modalCtrl: ModalController) {
		super(repository);
	}

	ngOnInit() {
		super.ngOnInit();
		this.inputNote = this.articleGroup?.note;
	}

	addNote() {
		this.modalCtrl.dismiss(this.inputNote);
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}
}
