import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {sleep} from '../../../smoothr-web-app-core/utils/utils';
import GeocoderResult = google.maps.GeocoderResult;

@Component({
	selector: 'app-custom-address-modal',
	templateUrl: './custom-address-modal.component.html',
	styleUrls: ['custom-address-modal.component.scss']
})
export class CustomAddressModalComponent implements OnInit {
	// @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
	// mapOptions: MapOptions = {
	// 	maxZoom: 15,
	// 	minZoom: 5,
	// 	disableDefaultUI: true,
	// 	clickableIcons: false,
	// 	// @ts-ignore
	// 	styles,
	// };
	// markers = [];
	// address: GeocoderResult;
	// center: LatLngLiteral;

	constructor(private viewCtrl: ModalController) {
	}

	static async show(modalCtrl: ModalController): Promise<{ address: GeocoderResult | null }> {
		const modal = await modalCtrl.create({
			component: CustomAddressModalComponent
		});
		await modal.present();
		const result = await modal.onDidDismiss();
		await sleep(100);
		return result.data;
	}

	async ngOnInit() {
		// this.address = await MapsUtils.getUserGeocode();
		// const place: Address = await MapsUtils.placeToAddress(this.address);
		// this.center = { lat: place.lat, lng: place.lng };
		// this.setMarker(this.center);
	}

	// async mapClick(latLng: any) {
	// 	await this.setMarker(latLng.toJSON());
	// }

	async dismiss() {
		await this.viewCtrl.dismiss({address: null});
	}

	// addMarker(latLng: LatLngLiteral, address: string) {
	// 	const marker = {
	// 		position: {
	// 			lat: latLng.lat,
	// 			lng: latLng.lng,
	// 		},
	// 		label: {
	// 			color: 'black',
	// 			text: address,
	// 		},
	// 		title: 'Marker title',
	// 		options: {
	// 			draggable: false,
	// 			icon: '../assets/icon/marker.svg',
	// 		},
	// 	};
	// 	this.markers = [];
	// 	this.markers.push(marker);
	// }

	// async setMarker(latLng) {
	// 	this.address = await MapsUtils.getPlaceLatLng(latLng);
	// 	const place: Address = await MapsUtils.placeToAddress(this.address);
	// 	this.addMarker({ lat: place.lat, lng: place.lng }, `${place.street}, ${place.number}`);
	// }

	// async geo() {
	// 	await this.viewCtrl.dismiss({ address: this.address });
	// }
}
