import {Component, Input, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';

@Component({
	selector: 'app-home-preorder-type-button',
	templateUrl: './home-preorder-type-button.component.html',
	styleUrls: ['home-preorder-type-button.component.scss']
})
export class HomePreorderTypeButtonComponent implements OnInit {
	environment = environment;
	@Input()
	preorderType: PreorderType;
	@Input()
	active = false;
	PreorderType = PreorderType;

	constructor() {
	}

	ngOnInit() {
	}
}
