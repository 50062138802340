import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const jamiesCustomerGroup = 'jamies';
export const customerGroup = jamiesCustomerGroup;

const supportEmail = {
	jamies: 'jamies@smoothr.de'
};
const firebaseConfig = {
	jamies: {
		apiKey: 'AIzaSyB1Y4wG6BoFE2hi8irI30ofKfscXoEPPDw',
		authDomain: 'prod-env-smoothr.firebaseapp.com',
		databaseURL: 'https://prod-env-smoothr.firebaseio.com',
		projectId: 'prod-env-smoothr',
		storageBucket: 'prod-env-smoothr.appspot.com',
		messagingSenderId: '640058019537',
		appId: '1:640058019537:web:f6fbfcc55d5358c7cbfff0',
		measurementId: 'G-ZKSKP5XCBQ'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyAPOO3lxiEcXe0-BU1oe2cwRTLHBkcJNZo',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment,
	countryList: ['de'],
	gaId: ''
};
